import Dashboard from 'pages/DashBorad';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutePath } from './constant';
import ExpertProfile from 'pages/ExpertProfile';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={RoutePath.dashboard} element={<Dashboard />} />
      <Route path={RoutePath.expertProfile} element={<ExpertProfile />} />
    </Routes>
  );
};

export default AppRoutes;
