// apiService.js
import axios from 'axios';

const GET_EXPERT_PROFILE = process.env.REACT_APP_GET_EXPERT_PROFILE;

const api = {
  getExpertData: (expertId) => {
    const formData = { expert_id: expertId };
  
    return axios.post(`${GET_EXPERT_PROFILE}get-expert-data`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
      
    });
  },
};

export default api;
